import React from 'react';
import { Orders_text } from '../../../constant/Translation/OrdersText';

const PricingItems = ({ order_details }) => {
  return (
    <div>
      {order_details?.customer_pricing &&
      order_details?.customer_pricing?.items?.length > 0 ? (
        <div>
          {order_details?.customer_pricing?.items?.map((item) => {
            return (
              <div className=" flex justify-between items-center py-2  ">
                <p className=" text-sm text-black font-semibold  ">
                  {item.name === 'Sub Total'
                    ? Orders_text.orderdetail.subtotal
                    : item.name === 'Total Tax'
                    ? Orders_text.orderdetail.tax
                    : item.name}
                </p>
                <p className=" text-sm text-black font-semibold flex items-center  ">
                  <span className=" ml-[6px] text-opacity-80">
                    {item?.amount.formatted}
                  </span>
                </p>
              </div>
            );
          })}

          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-lg text-black font-bold  ">
              {Orders_text.orderdetail.grand_total}
            </p>
            <p className=" text-lg text-black font-bold flex items-center  ">
              <span className=" ml-[6px] text-opacity-80">
                {order_details?.customer_pricing?.grand_total?.formatted}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">
              {Orders_text.orderdetail.subtotal}
            </p>
            <p className=" text-sm text-black font-semibold flex items-center  ">
              <span className="   text-opacity-80">
                {order_details?.list_total.formatted}
              </span>
            </p>
          </div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">
              {Orders_text.orderdetail.delivery_fee}
            </p>
            <p className=" text-sm text-black font-semibold    text-opacity-70 flex items-center flex-wrap">
              {order_details?.shipping_total.formatted}
            </p>
          </div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">
              {Orders_text.orderdetail.subtotal}
            </p>
            <p className=" text-sm text-black font-semibold flex items-center  ">
              <span className="   text-opacity-80">
                {order_details?.list_total.formatted}
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingItems;
