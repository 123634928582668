/* eslint-disable react/prop-types */
import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import Image from 'next/image'; // install Swiper modules
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { MainImageStyles } from '../../../constant/default_styles/ProductDetailsStyles';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const DynamicImagePart = ({ images, listing_details = {}, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {images ? (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: styles?.main_styles?.delay ?? 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
        >
          {images?.map((image, i) => {
            return (
              <SwiperSlide key={i} className="   ">
                <img
                  src={image}
                  alt={listing_details?.title}
                  layout="fill"
                  className="w-full "
                  objectFit="cover"
                  priority={true}
                  style={get_dynamic_styles_with_default_value(
                    styles?.image,
                    MainImageStyles
                  )}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <div className=" w-full aspect-1 bg-[#3B3269] bg-opacity-[10%] animate-pulse "></div>
      )}
    </div>
  );
};

export default DynamicImagePart;
