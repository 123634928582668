import Link from 'next/link';
import React from 'react';
import {
  facebook_icon,
  instagram_icon,
  linkedin_icon,
  snapchat_icon,
  telegram_icon,
  tiktok_icon,
  twitter_icon,
  whatsapp_icon,
  youtube_icon,
} from '../../Shared/Constant/Icons/socialIcons';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const SocialMedia = ({ styles, data }) => {
  return (
    <div className="flex" style={get_dynamic_styles(styles?.box_styles)}>
      {data?.facebook_pageurl && (
        <Link href={data?.facebook_pageurl}>
          <a target="_blank">{facebook_icon}</a>
        </Link>
      )}
      {data?.youtube_channelurl && (
        <Link href={data?.youtube_channelurl}>
          <a target="_blank">{youtube_icon}</a>
        </Link>
      )}
      {data?.twitter_handleurl && (
        <Link href={data?.twitter_handleurl}>
          <a target="_blank">{twitter_icon}</a>
        </Link>
      )}
      {data?.snapchat && (
        <Link href={data?.snapchat}>
          <a target="_blank">{snapchat_icon}</a>
        </Link>
      )}
      {data?.instagram_account && (
        <Link href={data?.instagram_account}>
          <a target="_blank">{instagram_icon}</a>
        </Link>
      )}
      {data?.telegram_url && (
        <Link href={data?.telegram_url}>
          <a target="_blank">{telegram_icon}</a>
        </Link>
      )}
      {data?.tiktok_url && (
        <Link href={data?.tiktok_url}>
          <a target="_blank">{tiktok_icon}</a>
        </Link>
      )}
      {data?.whatsapp_number && (
        <a
          className=" cursor-pointer"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send/?phone=${data?.whatsapp_number}`
            )
          }
          target="_blank"
        >
          {whatsapp_icon}
        </a>
      )}
      {data?.linkedin_url && (
        <Link href={data?.linkedin_url ?? '/'}>
          <a target="_blank">{linkedin_icon}</a>
        </Link>
      )}
    </div>
  );
};

export default SocialMedia;
